/**
 * Created by wangtong on 2022/2/7
 */

import {ApiV1Service, RequestConfig, Post, Get} from "@/api/base";
import * as models from "@/models"

class DynamicService extends ApiV1Service {
	@Get('/dynamic/share_data')
	async getShareData(payload: models.GetDynamicShareDataRequest, config?: RequestConfig): Promise<models.DynamicShareData> {
		return this.request(payload, config)
	}
	
	
}

export const DynamicApi = new DynamicService()

