


















/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {ImageStyle} from "@/constants";
import { buildOssUrl } from "@/utils";

@Component({})
export default class OssMultipleImageView extends Vue {
    @Prop({type: [Array], required: true}) imgPathList!: string[]
    @Prop() imgStyle?: ImageStyle

    buildThumbUrl(value: string): string {
        return buildOssUrl(value, this.imgStyle)
    }

    imagePreviewList(i: number) {
        if (!this.imgPathList) {
            return []
        }
        return [...this.imgPathList.slice(i, this.imgPathList.length), ...this.imgPathList.slice(0, i)].map(image => {
            return buildOssUrl(image)
        })
    }
}
