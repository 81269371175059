






/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {ImageStyle} from "@/constants";
import {buildOssUrl} from "@/utils";

@Component({})
export default class OssVideoView extends Vue {
    @Prop() videoPath!: string

    get url(): string {
        return buildOssUrl(this.videoPath)
    }
}
