










/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import OssImageView from "@/components/basic/OssImageView.vue";
import {GroupData} from "@/models";

@Component({
    components: {OssImageView}
})
export default class GroupShareItemView extends Vue {
    @Prop() group!: GroupData
}
