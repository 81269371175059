































/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import { DynamicData, DynamicShareData, UserData} from "@/models";
import UserDivisionAvatarView from "@/components/user/UserDivisionAvatarView.vue";
import OssVideoView from "@/components/basic/OssVideoView.vue";
import OssImageView from "@/components/basic/OssImageView.vue";
import OssAudioView from "@/components/basic/OssAudioView.vue";
import OssMultipleImageView from "@/components/basic/OssMultipleImageView.vue";
import GroupShareItemView from "@/components/group/GroupShareItemView.vue";
const icSplash = require("@/assets/images/ic_splash.png")

@Component({
    components: {
        GroupShareItemView,
        OssMultipleImageView, OssAudioView, OssImageView, OssVideoView, UserDivisionAvatarView}
})
export default class DynamicShareCard extends Vue {
    @Prop() shareId!: any
    @Prop() shareData!: DynamicShareData

    get shareLink(): string {
        return `https://sandanapp.com/share/dynamic/${this.shareId}`
    }

    get createUser(): UserData {
        return this.shareData.dynamic.create_user
    }

    get dynamicData(): DynamicData {
        return this.shareData.dynamic
    }
    icSplash = icSplash

    get dynamicImages(): string[] {
        return (this.shareData.dynamic.images?.map(x => x.path) || [])
    }

    get subtitle(): string {
        if (this.createUser.info.sign) {
            return this.createUser.info.sign
        } else {
            return `散弹号: ${this.createUser.info.sid}`
        }
    }
}
